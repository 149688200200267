<template>
  <base-layout>
    <template v-slot:title>
      <div class="text-lg">
        案件
      </div>
    </template>

    <router-view></router-view>
  </base-layout>
</template>